import React from 'react';
import { RouteProps } from 'react-router-dom';
import { demoPagesMenu, pageLayoutTypesPagesMenu, dashboardPagesMenu, clientPages } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const dynamicPath = '/category/item/:id';
const asides: RouteProps[] = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: clientPages.category.subMenu.grid.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: clientPages.landingPage.path, element: null },
	{ path: clientPages.category.subMenu.grid.path, element: null },
	{ path: `${clientPages.category.subMenu.itemID.path}/:id`, element: null },
	{ path: clientPages.bundles.subMenu.chatGPT.text, element: null },
	{ path: clientPages.bundles.subMenu.fast2SMS.text, element: null },
	{ path: clientPages.bundles.subMenu.sendBird.text, element: null },
	{ path: clientPages.bundles.subMenu.getStreamIO.text, element: null },
	{ path: clientPages.category.subMenu.itemID.path, element: null},
	{ path: clientPages.bundles.subMenu.AGORA.text, element: null},
	{ path: clientPages.bundles.subMenu.GoogleApiDirections.text, element: null},
	{path: clientPages.bundles.subMenu.GooglePlace.text, element:null},
	{path: clientPages.bundles.subMenu.GeocodingAPI.text, element:null},
	{path: clientPages.bundles.subMenu.GoogleApiStatic.text,element:null},
	{path: clientPages.bundles.subMenu.GoogleGeofenceApi.text,element: null,},
	{path: clientPages.bundles.subMenu.MapsEmbedAPI.text,element: null,	},
	{path: clientPages.bundles.subMenu.GeolocationAPI.text,element: null,},
	{path: clientPages.bundles.subMenu.GoogleRoadsAPI.text,element: null,},
	{path: clientPages.bundles.subMenu.Stripe.text,element:null,},
	{
		path: clientPages.bundles.subMenu.Zoom.text,
		element: null,
	},
	{ path: dynamicPath, element: null },



/**
	 * App > Bundles
	 */
{
	path: clientPages.bundles.subMenu.chatGPT.text,

element:null},
{
	path: clientPages.bundles.subMenu.Chatgpt4.text,

element:null},
{
	path: clientPages.bundles.subMenu.GPT4Turbo.text,

element:null},
{
	path: clientPages.bundles.subMenu.GPT4Turbowithvision.text,

element:null},
{
	path: clientPages.bundles.subMenu.GoogleDrive.text,

element:null},
{
	path: clientPages.bundles.subMenu.VertexAi.text,

element:null},
{path: clientPages.bundles.subMenu.GoogleApiDirections.text,
	

element:null},
{path: clientPages.bundles.subMenu.GoogleApiStatic.text,
	

element:null},
{path: clientPages.bundles.subMenu.GoogleGeofenceApi.text,
	

element:null},
{path: clientPages.bundles.subMenu.MapsEmbedAPI.text,
	

element:null},
{path: clientPages.bundles.subMenu.GeolocationAPI.text,
	

element:null},
{path: clientPages.bundles.subMenu.GoogleRoadsAPI.text,
	

element:null},
{path: clientPages.bundles.subMenu.GooglePlace.text,
	

	element:null
},
{path: clientPages.bundles.subMenu.GeocodingAPI.text,
	

	element:null
},
{ 
	path: clientPages.bundles.subMenu.fast2SMS.text,

element:null},
{
	path: clientPages.bundles.subMenu.sendBird.text,

element:null},
{
	path: clientPages.bundles.subMenu.getStreamIO.text,

element:null},
{
	path: clientPages.bundles.subMenu.AGORA.text,

element:null},

{
	path: clientPages.bundles.subMenu.Zoom.text,

element:null},
{
	path: clientPages.bundles.subMenu.StripeConnect.text,

element:null},
{

	path: clientPages.bundles.subMenu.Razorpay.text,

element:null},
{
	path: clientPages.bundles.subMenu.Stripe.text,

element:null},
{
	path: clientPages.bundles.subMenu.WebScraping.text,
	element: null,
},


	{ path: '*', element: <DefaultAside /> },
];
export default asides;
