import React, { ReactNode, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import useDarkMode from '../../../hooks/useDarkMode';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/authContext';
import { demoPagesMenu } from '../../../menu';
import useNavigationItemHandle from '../../../hooks/useNavigationItemHandle';
import Collapse from '../../../components/bootstrap/Collapse';
import { NavigationLine } from '../../../layout/Navigation/Navigation';
import Popovers from '../../../components/bootstrap/Popovers';
import { useFormik } from 'formik';
import Select from '../../../components/bootstrap/forms/Select';
import data from '../../../common/data/dummyProductData';
import { debounce } from '../../../helpers/helpers';
import { CATEGORIES } from '../../client-page/categories/DummyData';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../../store/category/CategorySlice';
import { getBundle, getBundlesbyCategory } from '../../../store/bundles/bundle/BundleSlice';
import { any } from 'prop-types';
const DashboardBookingHeader = () => {
	const user = JSON?.parse(localStorage?.getItem('login') || '{}');
	const location = useLocation();
	 const path = location.pathname  
	 const pathSegments = location.pathname.split('/');
	 const isBundleItemPath =  pathSegments[1]   
	 const isRootPath = location.pathname === '/' || isBundleItemPath === 'bunlde';
 	
	const names = user?.data?.name;
	const [name, setName] = React.useState(names);

	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const login = localStorage.getItem('login');

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		 
	};
	const { userData, setUser } = useContext(AuthContext);

	const navigate = useNavigate();

	const { t } = useTranslation(['translation', 'menu']);
	const [filterableData, setFilterableData] = useState(data);

	const onFormSubmit = (values: { category: any; search: any }) => {
		const searchValue = values.search.toString().toLowerCase();

		setFilterableData(data);
	};
	const formik = useFormik({
		initialValues: {
			search: '',
			category: '',
		},
		onSubmit: onFormSubmit,
		onReset: () => setFilterableData(data),
	});
	const categoriesSTATE = useSelector((state: any) => state.categories);
 
const [pagination, setPagination] = React.useState({
	pageNumber: 1,
	pageSize: 10,
});
	const dispatch2 = useDispatch<any>();
	useEffect(()=>{
 
		dispatch2(
			getCategory({
				pageNo: pagination.pageNumber,
				PAGE_SIZE: pagination.pageSize,
			} as any),
		);
	},[dispatch2,pagination])
	const CategoryOption = categoriesSTATE?.items?.map((item: any) => ({
		text: item?.name,
		value: item?._id,
	}));
	const [categ,setCateg]=useState<any>('')

	const handleCategory = (e:any)=>{
		setCateg(e.target.value)
	}
useEffect(()=>{

	if(categ === ''){
		dispatch2(getBundle({
			pageNo: pagination.pageNumber,
			PAGE_SIZE: pagination.pageSize,
		} as any))
	}
	else{

		dispatch2(getBundlesbyCategory(categ))
	}

},[categ,dispatch2,pagination])
	return (
		<Header>
			<div className='row'>
				<div className='col-2'>
					<HeaderLeft>
						<div className='d-flex align-items-center'>
							<div className='row g-4'>
								{ isRootPath  && <div className='col-md-auto'>
									<div
										className={classNames('fs-3', 'fw-bold', {
											'text-dark': !darkModeStatus,
										})}>
										{login ? (
											<div className='col-auto'>
												<Dropdown>
													<DropdownToggle hasIcon={false}>
														<Button
															{...styledBtn}
															style={{
															 
																border: 'none',
															}}
															isLight
								color='primary'
															icon='Person'
															aria-label='Quick menu'>
															{name}
														</Button>
													</DropdownToggle>
													<DropdownMenu
														isAlignmentEnd
														size='lg'
														className='py-0 overflow-hidden'>
														<div className='row g-0'>
															<div
																className={classNames(
																	'col-12',
																	'p-4',
																	'd-flex justify-content-center',
																	'fw-bold fs-5',
																	'text-info',
																	'border-bottom border-info',
																	{
																		'bg-l25-info':
																			!darkModeStatus,
																		'bg-lo25-info':
																			darkModeStatus,
																	},
																)}>
																Account Detail
															</div>
															<div
																className={classNames(
																	'col-6 p-4 transition-base cursor-pointer bg-light-hover',
																	'border-end border-bottom',
																	{
																		'border-dark':
																			darkModeStatus,
																	},
																)}>
																<div className='d-flex flex-column align-items-center justify-content-center'>
																	<Icon
																		icon='Public'
																		size='3x'
																		color='info'
																	/>
																	<span>Dealers</span>
																	<small className='text-muted'>
																		Options
																	</small>
																</div>
															</div>
															<div
																className={classNames(
																	'col-6 p-4 transition-base cursor-pointer bg-light-hover',
																	'border-bottom',
																	{
																		'border-dark':
																			darkModeStatus,
																	},
																)}>
																<div className='d-flex flex-column align-items-center justify-content-center'>
																	<Icon
																		icon='Upcoming'
																		size='3x'
																		color='success'
																	/>
																	<span>Inbox</span>
																	<small className='text-muted'>
																		Configuration
																	</small>
																</div>
															</div>
															<div
																className={classNames(
																	'col-6 p-4 transition-base cursor-pointer bg-light-hover',
																	'border-end border-bottom',
																	{
																		'border-dark':
																			darkModeStatus,
																	},
																)}>
																<div className='d-flex flex-column align-items-center justify-content-center'>
																	<div
																		role='presentation'
																		className='navigation-item cursor-pointer'
																		onClick={() =>
																			navigate(
																				`../${demoPagesMenu.appointment.subMenu.employeeID.path}/${userData?.id}`,
																				// @ts-ignore
																				handleItem(),
																			)
																		}>
																		<Icon
																			icon='AccountBox'
																			size='3x'
																			color='success'
																		/>
																		<span>Profile</span>
																	</div>
																</div>
															</div>

															<div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'>
																<div className='d-flex flex-column align-items-center justify-content-center'>
																	<div
																		role='presentation'
																		className='navigation-item cursor-pointer'
																		onClick={() => {
																			localStorage.removeItem(
																				'login',
																			);
																			localStorage.removeItem(
																				'logintoken',
																			);

																			navigate(`/`);
																		}}>
																		<Icon
																			icon='Logout'
																			size='3x'
																			color='warning'
																		/>
																		<span>Logout</span>
																	</div>
																</div>
															</div>
														</div>
													</DropdownMenu>
												</Dropdown>
											</div>
										) : (
											<Button
											isLight
								color='primary'
												style={{   listStyle: 'none' }}
												onClick={()=>navigate('/login')}>
												Login
											</Button>
										)}
									</div>
								</div>}
							</div>
						</div>
					</HeaderLeft>
				</div>
				<div className='col-2'>
				{user?.data?.roles == 'admin' && isRootPath && <div
						style={{
						 
							margin: '0 auto',
							maxHeight: 'unset',
						}}>
							<Button
  isLight
  color='primary'
  icon='Dashboard'
  onClick={() => window.open('/dashboard', '_blank')}
>
  Dashboard
</Button>
				</div>}
					</div>
				<div className='col-4'>
					<div
						style={{
							width: '200px',
							margin: '0 auto',
							maxHeight: 'unset',
						}}>
							<Link
												 
												to={'/'}>
						<img
							width={'100%'}
							src='https://do.meander.software/assets/logo-meander-black.png'
							alt=''
						/>

</Link>
					</div>
				</div>
				
				<div className='col-4 d-flex align-self-center'>
				{path === '/' && <form
						className={classNames('row', 'pt-2 px-3 mx-0 g-4', 'rounded-3')}
						onSubmit={formik.handleSubmit}>
						<div className='col-9 row	 ms-auto'>
							<div className="col-9" style={{paddingLeft:'0px',paddingRight:'0px'}}>
							<Select
								list={CategoryOption}
								onChange={handleCategory}
								required
								ariaLabel='apply_peak_fare'
								name='categoryId'
								id='categoryId'
								size='lg'
						 		placeholder='All Category'
								// list={CATEGORY_STATE.items.maps((item:any)=>item.name)}
								className={classNames(
									'rounded-1',
									{
										'bg-white': !darkModeStatus,
									},
									'border border-light',
								)}
								value={categ}
							/>
							</div>
							<div className='col-md-3' style={{paddingLeft:'10px',paddingRight:'0px'}}>
							<Button
							style={{paddingLeft:'0px',paddingRight:'0px'}}	
								size='lg' 
								isLight
								color='primary'
								 icon='Refresh'
								 className='px-3'
								rounded={1}
								onClick={()=>{setCateg('')}}
								type='reset'
								 
							> </Button>
						</div>
						</div>
					</form>}
				</div>
			</div>

			{/* <CommonHeaderChat /> */}
		</Header>
	);
};

export default DashboardBookingHeader;
// function dispatch(getCategory: any) {
// 	throw new Error('Function not implemented.');
// }

