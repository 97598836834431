import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
	clientPages,
	projectPages,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import SummaryHeader from '../pages/_layout/_headers/SummaryHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import PageLayoutHeader from '../pages/_layout/_headers/PageLayoutHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ChartsHeader from '../pages/_layout/_headers/ChartsHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import UtilitiesHeader from '../pages/_layout/_headers/UtilitiesHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import DocumentationHeader from '../pages/_layout/_headers/DocumentationHeader';
const dynamicPath = '/category/item/:id';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{ path: demoPagesMenu.knowledge.subMenu.grid.path, 
element:<DashboardBookingHeader/>	 },
	{ path: clientPages.category.subMenu.grid.path, 
element:<DashboardBookingHeader/>	}
,
// 	{
// 		path: projectPages.management.subMenu.UserManagement.path,
// element:<DashboardBookingHeader/>	},
	{ path: clientPages.bundles.subMenu.chatGPT.text, element: null },
	{ path: clientPages.bundles.subMenu.fast2SMS.text, element: null },
	{ path: clientPages.bundles.subMenu.sendBird.text, element: null },
	{ path: clientPages.bundles.subMenu.getStreamIO.text, element: null },
	{ path: dynamicPath,
element:<DashboardBookingHeader/>		 },
	{ path: dashboardPagesMenu.summary.path, element: null },
	{ path: clientPages.bundles.subMenu.AGORA.text,  element: null},
	{ path: `${clientPages.category.subMenu.itemID.path}/:id`,  
element:<DashboardBookingHeader/>},
	{ path: demoPagesMenu.login.path, element: null },
	{ path: clientPages.category.subMenu.grid.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: clientPages.landingPage.path, element: null },
	{ path: clientPages.category.subMenu.grid.path, element: null },
	{ path: `${clientPages.category.subMenu.itemID.path}/:id`, element: null },
	{ path: clientPages.bundles.subMenu.chatGPT.text,element:<DashboardBookingHeader/> },
	{ path: clientPages.bundles.subMenu.fast2SMS.text,element:<DashboardBookingHeader/> },
	{ path: clientPages.bundles.subMenu.sendBird.text,element:<DashboardBookingHeader/> },
	{ path: clientPages.bundles.subMenu.getStreamIO.text,element:<DashboardBookingHeader/> },
	{ path: clientPages.category.subMenu.itemID.path,element:<DashboardBookingHeader/>},
	{ path: clientPages.bundles.subMenu.AGORA.text,element:<DashboardBookingHeader/>},
	{ path: clientPages.bundles.subMenu.GoogleApiDirections.text,element:<DashboardBookingHeader/>},
	{path: clientPages.bundles.subMenu.GooglePlace.text, element:<DashboardBookingHeader/>},
	{path: clientPages.bundles.subMenu.GeocodingAPI.text, element:<DashboardBookingHeader/>},
	{path: clientPages.bundles.subMenu.GoogleApiStatic.text,element:<DashboardBookingHeader/>},
	{path: clientPages.bundles.subMenu.Stripe.text,element:<DashboardBookingHeader/>,},
	{path: clientPages.bundles.subMenu.StripeConnect.text,element:<DashboardBookingHeader/>,},

	// { path: dashboardPagesMenu.dashboard.path,element:<DashboardBookingHeader/> },
	{ path: clientPages.landingPage.path, element: null },
	{ path: dashboardPagesMenu.dashboard.path, element:
		 <DashboardBookingHeader /> 
		},
	{
		path: demoPagesMenu.projectManagement.subMenu.list.path,
element:<DashboardBookingHeader/>	},
	{ path: demoPagesMenu.pricingTable.path, 
element:<DashboardBookingHeader/>	 },
	{
		path: dashboardPagesMenu.dashboardBooking.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.appointment.subMenu.calendar.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.appointment.subMenu.employeeList.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.listPages.subMenu.listFluid.path,
element:<DashboardBookingHeader/>	},
	{
		path: `${demoPagesMenu.editPages.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${demoPagesMenu.appointment.subMenu.employeeID.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${demoPagesMenu.projectManagement.subMenu.itemID.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.singlePages.subMenu.fluidSingle.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.singlePages.subMenu.boxedSingle.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.Sales.subMenu.transactions.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.chat.subMenu.withListChat.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.chat.subMenu.onlyListChat.path,
element:<DashboardBookingHeader/>	},
	{
		path: `${demoPagesMenu.knowledge.subMenu.itemID.path}/:id`,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.crm.subMenu.dashboard.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.crm.subMenu.customersList.path,
element:<DashboardBookingHeader/>	},
	{
		path: `${demoPagesMenu.crm.subMenu.customerID.path}/:id`,
element:<DashboardBookingHeader/>	},
	{
		path: dashboardPagesMenu.summary.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.gridPages.subMenu.gridBoxed.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.gridPages.subMenu.gridFluid.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.listPages.subMenu.listBoxed.path,
element:<DashboardBookingHeader/>	},

	{
		path: clientPages.bundles.subMenu.Zoom.text,
		element: null,
	},
	{
		path: demoPagesMenu.Sales.subMenu.descriptionList.path,
element:<DashboardBookingHeader/>	},
	{
		path: demoPagesMenu.Sales.subMenu.productsGrid.path,
element:<DashboardBookingHeader/>	},
	{
		path: `${demoPagesMenu.Sales.subMenu.productID.path}/:id`,
element:<DashboardBookingHeader/>	},
	{
		path: `${pageLayoutTypesPagesMenu.asideTypes.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
element:<DashboardBookingHeader/>	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
element:<DashboardBookingHeader/>	},
	{
		path: `${componentPagesMenu.components.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${componentPagesMenu.forms.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${componentPagesMenu.charts.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${componentPagesMenu.content.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${componentPagesMenu.utilities.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${componentPagesMenu.icons.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${gettingStartedPagesMenu.gettingStarted.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `${gettingStartedPagesMenu.routes.path}/*`,
element:<DashboardBookingHeader/>	},
	{
		path: `*`,
element:<DashboardBookingHeader/>	},
];

export default headers;
