import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeadsCreate,   } from './LeadApi'; // Assuming you have a type like ApiResponse

export interface CategoryState<T> {
  items: T[];
  loading: boolean;
  current: T | null;
}

export interface ICategory {
  pageNo: number;
  PAGE_SIZE: number;
  token: string;
  body: any;
  CategoryId: any;
  value: any;
  _id: any;
}

const initialState: CategoryState<ICategory> = {
  items: [],
  current: null,
  loading: false,
};

export const LeadsPost = createAsyncThunk('Leads/LeadsPost', async function (body: any) {
  const response = await LeadsCreate(body);
  
  return response; 
   

});
 
const LeadsSlice = createSlice({
  name: 'LeadsSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LeadsPost.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(LeadsPost.fulfilled, (state, action:any) => {
        // state.items = action.payload;

        console.log('====================================');
        console.log(action.payload);
        console.log('====================================');
        state.loading = false;
      })
      .addCase(LeadsPost.rejected, (state) => {
        state.loading = false;
      })
      
  },
});

export default LeadsSlice.reducer;
