import axios from "axios";
import { BASE_URL } from "../../../API";

export interface IBundle {
	pageNo: number;
	PAGE_SIZE: number;
	token: string;
	body: any;
	BundleId: any;
	_id: any;
}

// Get Bundle//
// Get Bundle//
export const getBundles =  async (  pageNo: number, PAGE_SIZE: number) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			 
		},
	};
	const response = await fetch(
		`${BASE_URL}/api/bundles/getAll?pageNo=1&pageSize=10000`,
		options,    
	);
	const data = await response.json();
	return data;
};


// Get by id Bundle//
export const getByIdBundles =  async (valueID:string) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			 
		},
	};
	const response = await fetch(
		`${BASE_URL}/api/bundles/getById/${valueID}`,
		options,    
	);
	const data = await response.json();
	return data;
};









export const getBundlesbyCategoryId =  async ( value:any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			 
		},
	};
	const response = await fetch(
		`${BASE_URL}/api/bundles/getByCategory/${value}`,
		options,    
	);
	const data = await response.json();
	return data;
};
export const getBysearchBundles =  async ( Value:any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			 
		},
	};
	const response = await fetch(
		`${BASE_URL}/api/bundles/searchBundle?search=${Value}`,
		options,    
	);
	const data = await response.json();
	return data;
};


export const getByFilterStatus=  async (Value:any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			 
		},
	};
	const response = await fetch(
		 
		`${BASE_URL}/api/bundles/filterByStatus?status=${Value}`,
		options,    
	);
	const data = await response.json();
	return data;
};
// Post Bundle//
export const postBundle = async (body: any) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			// "x-access-token": token,
		},
		body: JSON.stringify(body),
	};
	const response = await fetch(`${BASE_URL}/api/bundles/create`, options);
	const data = await response.json();
	return data;
};




export const uploadProfilePicture = async (imageData:any, userId:any) => {
    try {
      const formData = new FormData();
      formData.append('image', imageData);
       
  
      const response = await axios.put(`${BASE_URL}/api/bundles/uploadImageById/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      return response.data;
    } catch (error) {
      throw error;
    }
  };
// Delete Bundle//
export const deleteBundle = async (BundleId: any, token: string) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	};
	const response = await fetch(`${BASE_URL}/api/bundles/remove/${BundleId}`, options);
	const data = await response.json();
	return data;
};

//* update Bundle/
export const editBundles = async (body: any,  _id: any) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			// 'x-access-token': token,
		},
		body: JSON.stringify(body),
	};

	const response = await fetch(`${BASE_URL}/api/bundles/update/${_id}`, options);
	const data = await response.json();
	return data;
};


//* update Bundle/
export const UpdateFieldsOpenAI = async (Data: any) => {
	const {id,tagID}=Data
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			// 'x-access-token': token,
		} 
	};

	const response = await fetch(`${BASE_URL}/api/bundles/getDescription/${id}?tag=${tagID}`, options);
	const data = await response.json();
	console.log('====================================');
	console.log(data);
	console.log('====================================');
	return data;
};




//* update Bundle/
export const UpdateAdvanceDetail = async (_id: any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			// 'x-access-token': token,
		} 
	};

	const response = await fetch(`${BASE_URL}/api/bundles/getAdvanceDetail/${_id}`, options);
	const data = await response.json();
	return data;
};
//* update Bundle/
export const UpdateMetaDetail = async (_id: any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			// 'x-access-token': token,
		} 
	};

	const response = await fetch(`${BASE_URL}/api/bundles/getMetaDetail/${_id}`, options);
	const data = await response.json();
	return data;
};
export const UpdateRefrencesDetail = async (_id: any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			// 'x-access-token': token,
		} 
	};

	const response = await fetch(`${BASE_URL}/api/bundles/getRefrencesDetail/${_id}`, options);
	const data = await response.json();
	return data;
};