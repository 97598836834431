import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { createProfile, createUsers, deleteUser, searchUser, sortUser, updateUser, getbyiduser, searchUserbyAge, RemoveImageintoGallery, uploadGalleryImage, getClientAndAdmin } from "../Api/UserManagement";

interface UserState {
  users: any[];
  user: any;
  page: number;
  loading: boolean;
  error: null | string;
}

const initialState: UserState = {
  users: [],
  user: null,
  page: 1,
  loading: false,
  error: null,
};

// Generic async function thunk creator
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
  return createAsyncThunk<any, any>(
    `user/${name}`,
    async (data: any) => {
      try {
        const response: AxiosResponse<any, any> = await apiFunction(data);
        return response.data;
      } catch (error) {
        console.error(`Failed to ${name}:`, error);
        throw error;
      }
    }
  );
};

// Create user with additional profile creation logic
export const createUser = createAsyncThunk<any, any>(
  'user/createUser',
  async (data: any) => {
 
    try {
      const response: AxiosResponse<any, any> | undefined = await createUsers(data);
      const imageid = response.data.data._id;
       
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }
);

// Generic user async thunks
export const uploadGalleryImages = AsyncFunctionThunk('uploadGalleryImages', uploadGalleryImage);
export const fetchUser = AsyncFunctionThunk('fetchUser', getClientAndAdmin);
export const getbyidusers = AsyncFunctionThunk('getbyidusers', getbyiduser);
export const fetchdeleteUser = AsyncFunctionThunk('fetchdeleteUser', deleteUser);
export const fetchupdateUser = AsyncFunctionThunk('fetchupdateUser', updateUser);
export const RemoveImageintoGallerys = AsyncFunctionThunk('RemoveImageintoGallerys', RemoveImageintoGallery);
export const fetchsearchUser = AsyncFunctionThunk('fetchsearchUser', searchUser);
export const searchUserbyAges = AsyncFunctionThunk('searchUserbyAges', searchUserbyAge);
export const fetchsortUser = AsyncFunctionThunk('fetchsortUser', sortUser);

// User slice definition
const userSlice:any = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state, action) => {
        console.log('====================================');
        console.log(action.payload);
        console.log('====================================');
        // state.users.push(action.payload);
        state.loading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error creating user";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.users = action.payload?.data?.items;
        state.loading = false;
      })
      .addCase(getbyidusers.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchdeleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user._id !== action.meta.arg);
        state.loading = false;
      })
      .addCase(fetchsearchUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(searchUserbyAges.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(fetchsortUser.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(RemoveImageintoGallerys.fulfilled, (state) => {
        state.loading = false;
      });
  }
});

export const { setPage } = userSlice.actions;

export default userSlice.reducer;
