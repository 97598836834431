import axios from "axios"
import { DoMeanderSoftware, getToken } from "../../API";
 
 
 
export const createUsers = async (data:any) => {
  const { name,email,password,dob,sex,address,roles,phone,image,} = data;

  const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('dob', dob);
  formData.append('sex', sex);
  formData.append('address', address);
  formData.append('roles', roles);
  formData.append('phone', phone);
  formData.append('image', image);

  try {
    const response = await axios.post(`${DoMeanderSoftware}users/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getClientAndAdmin=async(data:any)=>{
    try {
        const resoponse = await axios.get(`${DoMeanderSoftware}client/getClientAndAdmin?page_no=$1&page_size=100`)
        return resoponse
    } catch (error) {
      console.error(error) 
      throw error;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
    }
}


export const updateUser = async (data: any) => {
   


  const { name,email,password,dob,sex,address,roles,phone,image,editId} = data;

  const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('dob', dob);
  formData.append('sex', sex);
  formData.append('address', address);
  formData.append('roles', roles);
  formData.append('phone', phone);
  formData.append('image', image);

  const option={
    headers: {
       
        'x-access-token':getToken()
      }
   }
  try {
    const response:any = await axios.put(
      `${DoMeanderSoftware}users/update/${editId}`,
      formData,option
    );
    return response.data;  
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getbyiduser=async(data:any)=>{
  
   const option={
    headers: {
       
        'x-access-token':getToken()
      }
   }
    try {
        const resoponse = await axios.get(`${DoMeanderSoftware}User/getById/${data}`,option)
        return resoponse
    } catch (error) {
      console.error(error) 
      throw error;   
    }
}

export const deleteUser=async(id:any)=>{
    const option={
     headers: {
        
         'x-access-token':getToken()
       }
    }
     try {
         const resoponse = await axios.delete(`${DoMeanderSoftware}users/delete/${id}`,option)
         return resoponse
     } catch (error) {
       console.error(error) 
       throw error;   
     }
 }
 export const updatestatus = async (data: any) => {
  const { editId, status } = data;
  const options = {
    method:'PUT',
    headers: {
      'content-type':'application/json',
      'x-access-token':getToken(),
    },
    body: JSON.stringify({is_activated:status}),
  };
  try {
    const response:any = await fetch(
      `${DoMeanderSoftware}User/update/${editId}`,
      options
    );
    return response.data; // Fixed the variable name here
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const searchUser = async (name: any) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(
      `${DoMeanderSoftware}User/filter?name=${name}`,
      options
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }

    const responseData = await response.json();
    
    return responseData; // Assuming the response is already JSON, no need to stringify.
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const searchUserbyAge = async (data:any) => {

  const {gender, minAge, maxAge}=data
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const response = await fetch(
      `${DoMeanderSoftware}User/filter?gender=${gender}&minAge=${minAge}&maxAge=${maxAge}`,
      options
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }

    const responseData = await response.json();
    
    return responseData; // Assuming the response is already JSON, no need to stringify.
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const sortUser =async(sort:any)=>{
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const response = await fetch(
      `${DoMeanderSoftware}User/sort?sort_by_name=${sort}`,
      options
    );
    const responseData = await response.json();
    
    return responseData; 
  } catch (error) {
    console.error(error);
    throw error;
  }
}




export const createProfile = async (data:any) => {
  const { avatarFil } = data;

  const formData = new FormData();
  formData.append('image', avatarFil); // Assuming 'avatar' is the key for the file in the FormData
  const options = {
    method:'PUT',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'x-access-token':getToken(),

    },
   

    body:formData
  };

  try {
    const response = await fetch(`${DoMeanderSoftware}User/uploadMainProfile/${data.imageid}`, options);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const uploadGalleryImage = async (data:any) => {
  const { avatarFil } = data;

  const formData = new FormData();
  formData.append('image', avatarFil); // Assuming 'avatar' is the key for the file in the FormData
  const options = {
    method:'PUT',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'x-access-token':getToken(),

    },
   

    body:formData
  };

  try {
    const response = await fetch(`${DoMeanderSoftware}User/uploadProfile/${data.id}`, options);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const RemoveImageintoGallery = async (data:any) => {
  const  {id,item} = data 
  console.log(id, item);
  
  const options = {
    method:'DELETE',
    headers: {
  
      'x-access-token':getToken(),

    },
   

  
  };
  

  try {
    const response = await fetch(`${DoMeanderSoftware}User/${id}/remove-avatar/${item}`, options);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
