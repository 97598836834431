import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagsCateGetAll, TagsGetByTagCategoryId } from './Tag&CApi'; // Assuming you have a type like ApiResponse
import { AxiosResponse } from 'axios';
 

export interface CategoryState<T> {
  items: T[];
  loading: boolean;
  current: T | null;
  TagWithCategory:[]
}

export interface ICategory {
  pageNo: number;
  PAGE_SIZE: number;
  token: string;
 
  body: any;
  CategoryId: any;
  value: any;
  _id: any;
}

const initialState: CategoryState<ICategory> = {
  items: [],
  TagWithCategory:[],
  current: null,
  loading: false,
};


const AsyncFunctionThunk = (name: string, apiFunction: any) => {
  return createAsyncThunk<any, any>(
      `TagsCates/${name}`,
      async (data: any, { rejectWithValue }) => {
          try {
              const response: AxiosResponse<any, any> = await apiFunction(data);
              return response.data;
          } catch (error: any) {
              console.error(`Failed to ${name}:`, error);
              if (error.response && error.response.data) {
                  return rejectWithValue(error.response.data);
              }
              return rejectWithValue({ error: error.message });
          }
      }
  );
};



export const TagsCateGetAllS = AsyncFunctionThunk('TagsCateGetAllS', TagsCateGetAll);
export const TagByTCId = AsyncFunctionThunk('TagsGetByTagCategoryId', TagsGetByTagCategoryId);

  
const TagsCatesSlice = createSlice({
  name: 'TagsCatesSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TagsCateGetAllS.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagsCateGetAllS.fulfilled, (state, action: PayloadAction<any>) => {
        state.items = action.payload?.items;
        state.loading = false;
      })
      .addCase(TagsCateGetAllS.rejected, (state) => {
        state.loading = false;
      })
      .addCase(TagByTCId.fulfilled,(state, action:any)=>{
        
     
          state.TagWithCategory = action.payload
      
      })
      .addCase(TagByTCId.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagByTCId.rejected, (state) => {
        state.loading = false;
      })
    }
});

export default TagsCatesSlice.reducer;
