import axios from "axios";
import { DoMeanderSoftware } from "../../API";
 
 
 

export const LoginAuth = async (data:any) => {
    const {loginData}=data
  

    try {
        const resoponse = await axios.post(`${DoMeanderSoftware}users/login`,loginData)
      
        // if (resoponse?.data?.isSuccess) {
        //     localStorage.setItem('login', JSON.stringify(resoponse?.data));
		// 	localStorage.setItem('facit_authUsername',resoponse?.data?.name);
		// 	localStorage.setItem('logintoken',JSON.stringify(resoponse?.data?.data?.token) )
        // }
       
        return resoponse
    } catch (error) {
 
      throw error;    
    }
  };


//   export const LoginAuthWithPhone = async (data) => {

//     try {
//         const resoponse = await axios.post(`${DoMeanderSoftware}User/loginwithemailorcontact?mode=${Active_Mode}`,data)
      
//    localStorage.setItem("ChatUp_Login",JSON.stringify(resoponse.data.data))
       
//         return resoponse
//     } catch (error) {
 
//       throw error;    
//     }
//   };
// export const CreateAuth = async (data) => {

//   try {
//       const resoponse = await axios.post(`${DoMeanderSoftware}User/create`,data)
    
     
//       return resoponse
//   } catch (error) {
//     console.log(error)
//     throw error;    
//   }
// };