import axios from "axios"
import { BASE_URL } from "../../API"

 

export const TagsCreate=async(data:any)=>{
const options = {
    method:'POST',
    headers:{
        'content-Type':'application/json',

    },
    body:JSON.stringify(data)

    
}
const response = await fetch(`${BASE_URL}/api/tags/create`,options)
return response
}


export const TagsGetAll = async(item:any)=>{
    const options={
        method:'GET',
        headers:{
            'content-Type':'application/json'
        }
    }
    const response =await fetch(`${BASE_URL}/api/tags/getTags?pageNo=1&pageSize=100`,options)
    const data = await response.json();
	return data;
}


export const TagsGetByTagCategoryId = async(data:any)=>{
    const {tagid}=data
    const response =await axios.get(`${BASE_URL}/api/tags/getTagByTag_categoriesId/${tagid}`)
	return response.data;
}


// edit

export const TagsEdit = async(data:any)=>{
    const{id,values}=data
    console.log(data?._id)
    const options={
        method:'PUT',
        headers:{
            'content-Type':'application/json',
    
        },
        body:JSON.stringify(values)
    }
    const response = await fetch(`${BASE_URL}/api/tags/update/${id}`,options)
    return response
}

// delete 

export const TagsDelete =async(id:any)=>{
    const options={
        method:'DELETE',
        headers: {
            'Content-Type': 'application/json'  // Fix the typo here
        }
    }
    const response = await fetch(`${BASE_URL}/api/tags/delete/${id}`,options)
    return response
}