import axios from 'axios';
import { BASE_URL } from '../../API';

export interface ICategory {
	pageNo: number;
	PAGE_SIZE: number;
	token: string;
	body: any;
	CategoryId: any;
	_id: any;
}

// Get Category//
export const getCategorys = async () => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			 
		},
	};
	const response = await fetch(
		`${BASE_URL}/api/categories/getAll?pageNo=1&pageSize=11000`,
		options,
	);
	const data = await response.json();
	return data;
};

// Search Category//
export const SearchCategory = async (
	token: string,
	pageNo: number,
	PAGE_SIZE: number,
	value: any,
) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	};
	const response = await fetch(
		`${BASE_URL}/api/categories/search=${pageNo}&pageSize=${PAGE_SIZE}&value=${value}`,
		options,
	);
	const data = await response.json();
	console.log(data, 'ggreytiyti');
	return data;
};

// Get Category By Id//
export const getCategorysById = async (token: string, CategoryId: any) => {
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	};
	const response = await fetch(`${BASE_URL}/api/bundles/getByCategory/${CategoryId}`, options);
	const data = await response.json();
	return data;
};

// Post Category//
export const postCategory = async (body: any) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			// "x-access-token": token,
		},
		body: JSON.stringify(body),
	};
	const response = await fetch(`${BASE_URL}/api/categories/create`, options);
	const data = await response.json();
	return data;
};


// Delete Category//
export const deleteCategory = async (CategoryId: any, token: string) => {
	const options = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
	};
	const response = await fetch(`${BASE_URL}/api/categories/remove/${CategoryId}`, options);
	const data = await response.json();
	return data;
};

//* update Category/
export const editCategorys = async (body: any, token: string, _id: any) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'x-access-token': token,
		},
		body: JSON.stringify(body),
	};

	const response = await fetch(`${BASE_URL}/api/categories/update/${_id}`, options);
	const data = await response.json();
	return data;
};
